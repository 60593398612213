import React, { Suspense, lazy, useState, useEffect, useCallback } from 'react';
import { useNavigate, useMatch, useSearchParams } from 'react-router-dom';

import TitleBar from 'components/Menu/TitleBar';
import ErrorBoundary from 'components/Editor/Common/ErrorBoundary.js';
import Backup from 'containers/Backup';
import { createPost, submitPostToBackend } from './lib/service';
import {
  useFetchSiteData,
  useFetchUserData,
  useUnsavedFormWarning,
} from './lib/hookHelpers';
import { isFuture } from './lib/momentHelper';
import helpers from 'utils/generatehash';
import DevTools from 'devTools';
import { init as initCheck, isValidUser } from 'lib/check';
import { isMobile } from 'lib/deviceDetect';
import { POST_TYPE_LANDING } from 'lib/constants';
import { setTimezone, currentTime } from 'containers/lib/momentHelper';
import { onlinePresence } from 'lib/firebase';
import MultipleActivePostUserMessage from '../components/Editor/MultipleActivePostUserMessage';
import { UnsavedConfirmationSnackbar } from 'components/Editor/Common/Snackbar/UnsavedConfirmationSnackbar';
import i18n from 'i18n.js';

const Publish = lazy(() =>
  import(/* webpackChunkName: "Publish" */ './Publish')
);
const Escribir = lazy(() =>
  import(/* webpackChunkName: "Escribir" */ './Escribir')
);
const Difundir = lazy(() =>
  import(/* webpackChunkName: "Difundir" */ './Difundir')
);
const Editor = lazy(() => import(/* webpackChunkName: "Lfe" */ './Editor'));
const Modals = lazy(() => import(/* webpackChunkName: "Modals" */ './Modals'));

const loading = <div className="grid-wrapper">Loading...</div>;

let editor = null;
const handleRef = (ref) => {
  editor = ref;
};

type Props = {
  visitorId: number,
};

export const Layout = React.memo(({ visitorId }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [adminUrl, siteUrl, siteError, blogName] = useFetchSiteData();
  const [user, errors] = useFetchUserData(blogName, adminUrl);
  setTimezone(blogName);
  const match = useMatch('/:tabName/:hashId');
  const { tabName, hashId } = match.params;
  const [showDifundir, setShowDifundir] = useState(false);
  const [hasUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [enableBrowserWarning, setEnableBrowserWarning] = useState(false);
  const [, showMessage, setShowMessage] = useUnsavedFormWarning(
    hasUnsavedChanges,
    enableBrowserWarning
  );

  const [postStatus, setPostStatus] = useState({
    showPostStatusMsg: false,
    statusMsg: '',
  });
  const [_isMobile, setIsMobile] = useState(() => isMobile());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(isMobile());
    });

    const init = async () => {
      const siteLanguageMap = {
        xatakaon: 'en',
        xatakaen: 'en',
        xatakabrasil: 'pt',
      };
      const siteLanguage = siteLanguageMap[blogName] || 'es';
      i18n.changeLanguage(siteLanguage);

      if ('new' === hashId) {
        const postType = searchParams.get('type');
        initCheck(postType, user.userRole);
        if (!isValidUser()) {
          throw new Error('NOT_AUTHORIZED');
        }

        const hashId = helpers.generatePushID();
        const postEditUrl = `/escribir/${hashId}`;
        navigate(postEditUrl);
        const { id } = await submitPostToBackend(
          {
            id: hashId,
            status: 'draft',
            userId: user.currentUser,
            title: '(sin título)',
            postType,
          },
          adminUrl
        );
        const initialData = {
          id: hashId,
          user_id: user.currentUser,
          user_name: user.currentUserDisplayName,
          postType,
          initialPostType: postType,
          blogName,
          status: 'draft',
          title: '',
          sections: [],
          postVisibility: ![POST_TYPE_LANDING].includes(postType),
          publishData: {
            postId: id,
          },
        };
        await createPost(initialData);
      }
    };
    if (user.userRole) {
      init();
      if (hashId !== 'new') {
        const activeUser = () => {
          const userData = {
            ...user,
            userAgent: navigator.userAgent,
            dateTime: currentTime(),
          };
          onlinePresence(`posts/${hashId}/online/${visitorId}`, userData);
        };
        activeUser();
        document.addEventListener('visibilitychange', () => {
          if ('visible' === document.visibilityState) {
            activeUser();
          }
        });
      }
    }
  }, [searchParams, hashId, user, navigate, blogName, adminUrl, visitorId]);

  const handleStatus = useCallback(
    (statusMsg: string) => {
      setPostStatus({
        showPostStatusMsg: true,
        statusMsg: statusMsg,
      });
    },
    [setPostStatus]
  );

  const hideStatus = useCallback(() => {
    setPostStatus({
      showPostStatusMsg: false,
      statusMsg: '',
    });
  }, [setPostStatus]);

  const handleDifundir = (
    status: string,
    publishedDate: string,
    postType: string
  ) => {
    let showDifundir = false;
    let showPostStatusMsg = false;

    if (status === 'publish') {
      showPostStatusMsg = true;
      const { userRole } = user;
      if ('ROLE_BRANDED_COORDINATOR' === userRole) {
        showDifundir =
          ['brandArticle', 'brandAdvertising', 'branded'].indexOf(postType) >
          -1;
      } else if (
        !['ROLE_BRANDED_COLLABORATOR', 'ROLE_COLABORADOR'].includes(userRole)
      ) {
        showDifundir = true;
      }
    }

    let statusMsg = isFuture(publishedDate) ? 'Programado' : 'Publicado';

    setPostStatus({
      showPostStatusMsg,
      statusMsg,
    });
    setShowDifundir(showDifundir);
  };

  const handleClose = () => {
    setIsUnsavedChanges(false);
    setShowMessage(false);
    navigate(-1);
  };

  const handleConfirm = () => {
    setShowMessage(false);
  };

  const getTitleBar = () => {
    return (
      <TitleBar
        navigate={navigate}
        activeTab={tabName}
        postName={hashId}
        siteUrl={siteUrl}
        adminUrl={adminUrl}
        showDifundir={showDifundir}
        blogName={blogName}
        showPostStatusMsg={postStatus.showPostStatusMsg}
        statusMsg={postStatus.statusMsg}
        saveData={editor && editor.saveData}
        toggleOrderMode={editor && editor.toggleOrderMode}
        hideStatus={hideStatus}
        isMobile={_isMobile}
        moduleName={'createPost'}
        setIsUnsavedChanges={setIsUnsavedChanges}
        setShowMessage={setShowMessage}
      />
    );
  };

  if (errors.hasError || siteError.hasError) {
    return (
      <React.Fragment>
        <h1>Something went wrong.</h1>
        <pre>{errors.errorStack}</pre>
        <pre>{siteError.errorStack}</pre>
      </React.Fragment>
    );
  }

  const rest = {
    adminUrl,
    siteUrl,
    blogName,
    isMobile: _isMobile,
    ...user,
  };

  const unsavedWarningInfo = {
    message:
      tabName === 'escribir'
        ? `Se ha reportado que usar el botón "atrás" puede causar pérdida de contenido. Antes de usarlo, se recomienda descargar una copia de seguridad por precaución.`
        : `Hay cambios sin guardar en este formulario, si lo cierras, se perderán`,
    confirmationMessage:
      tabName === 'escribir'
        ? 'Seguir en el editor'
        : 'Seguir en el formulario',
    cancelMessage:
      tabName === 'escribir' ? 'Ignorar e ir atrás' : 'Descartar cambios',
  };

  return (
    <ErrorBoundary>
      {getTitleBar()}
      <Suspense fallback={loading}>
        {showMessage && (
          <UnsavedConfirmationSnackbar
            isUnsavedContent={hasUnsavedChanges}
            onClose={handleClose}
            onConfirmation={handleConfirm}
            message={unsavedWarningInfo.message}
            confirmationMsg={unsavedWarningInfo.confirmationMessage}
            cancelMsg={unsavedWarningInfo.cancelMessage}
          />
        )}
        {tabName === 'escribir' ? (
          <Escribir
            tabName={tabName}
            onRef={handleRef}
            navigate={navigate}
            postName={hashId}
            {...rest}
            handleStatus={handleStatus}
            setIsUnsavedChanges={setIsUnsavedChanges}
            showMessage={showMessage}
            hasUnsavedChanges={hasUnsavedChanges}
          />
        ) : null}
        {tabName === 'backup' ? (
          <Backup navigate={navigate} postName={hashId} siteName={blogName} />
        ) : null}
        {tabName === 'publicar' ? (
          <Publish
            {...rest}
            postName={hashId}
            navigate={navigate}
            handleDifundir={handleDifundir}
          />
        ) : null}
        {tabName === 'difundir' ? (
          <Difundir
            {...rest}
            postName={hashId}
            navigate={navigate}
            handleDifundir={handleDifundir}
            siteName={blogName}
            setIsUnsavedChanges={setIsUnsavedChanges}
            setEnableBrowserWarning={setEnableBrowserWarning}
          />
        ) : null}

        {siteUrl && tabName === 'edit' ? (
          <div className="container-fluid" style={{ paddingTop: '112px' }}>
            <Editor
              onRef={handleRef}
              navigate={navigate}
              {...rest}
              handleStatus={handleStatus}
            />
          </div>
        ) : null}
        <Modals adminUrl={adminUrl} siteName={blogName} isMobile={_isMobile} />
        {hashId !== 'new' && user.hasOwnProperty('currentUser') && (
          <MultipleActivePostUserMessage
            postName={hashId}
            visitorId={visitorId}
          />
        )}
      </Suspense>
      <DevTools />
    </ErrorBoundary>
  );
});
