import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Error } from 'components/Editor/Common';

const theme = createTheme({
  palette: {
    error: {
      main: '#C52100',
    },
  },

  overrides: {
    root: {
      '&$error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C52100 !important',
        },
      },
    },

    MuiInputLabel: {
      outlined: {
        transform: 'translate(10px, -6px) scale(0.75) !important',
        background: '#ffffff',
        padding: '0 5px',
      },
    },

    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiListItem: {
      root: {
        maxWidth: '450px',
        display: 'block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',

        '&$selected, &$selected:hover': {
          backgroundColor: '#EEEEEE',
        },
      },
      button: {
        '&:hover': {
          backgroundColor: '#FCE4EC',
        },
      },
    },

    MuiSelect: {
      root: {
        background: 'white',
      },
      select: {
        '&:focus': {
          backgroundColor: 'initial',
        },
      },
      selectMenu: {
        minHeight: '40px',
        boxSizing: 'border-box',
        padding: '10px 8px 6px 14px !important',
        lineHeight: '24px',
      },
      icon: {
        color: '#111111',
      },
    },
  },
});

type Props = {
  label: string,
  items: Array<{ label: string, value: string }> | Array<string>,
  disabledItems: Array<string>,
  value: string | { label: string, value: string },
  onChange: Function,
  name?: String,
  errorText?: string,
};

const isDisabled = (value, disabledItems) => {
  return disabledItems.includes(value);
};

export const SimpleSelect = ({
  label,
  items,
  disabledItems = [],
  value,
  name,
  onChange,
  errorText,
}: Props) => {
  return (
    <MuiThemeProvider theme={theme}>
      <FormControl variant="outlined" error={!!errorText}>
        <InputLabel>{label}</InputLabel>
        <Select name={name} value={value} label={label} onChange={onChange}>
          {items.map((item, index) => {
            let value, label;
            if (typeof item === 'string') {
              value = item;
              label = item;
            } else {
              value = item.value;
              label = item.label;
            }
            if (value === '' || value === '(Ninguno)') {
              return (
                <MenuItem key={index} value={value}>
                  <span style={{ color: '#757575' }}>{label}</span>
                </MenuItem>
              );
            }
            return (
              <MenuItem
                key={index}
                value={value}
                disabled={isDisabled(value, disabledItems)}
              >
                <span>{label}</span>
              </MenuItem>
            );
          })}
        </Select>
        {!!errorText && (
          <Error
            margin="fieldError"
            fontsize="small"
            message={errorText}
            showIcon={false}
          />
        )}
      </FormControl>
    </MuiThemeProvider>
  );
};
