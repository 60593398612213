const sites = {
  xataka: {
    label: 'Xataka',
    value: 'xataka',
    instagramProfile: true,
    flipboard: true,
    domain: 'www.xataka.com',
    testingDomain: 'testing.xataka.com',
  },
  xatakamovil: {
    label: 'Xataka Móvil',
    value: 'xatakamovil',
    instagramProfile: false,
    flipboard: true,
    domain: 'www.xatakamovil.com',
    testingDomain: 'testing.xatakamovil.com',
  },
  xatakandroid: {
    label: 'Xataka Android',
    value: 'xatakandroid',
    instagramProfile: false,
    flipboard: true,
    domain: 'www.xatakandroid.com',
    testingDomain: 'testing.xatakandroid.com',
  },
  xatakahome: {
    label: 'Xataka Smart Home',
    value: 'xatakahome',
    instagramProfile: false,
    flipboard: true,
    domain: 'www.xatakahome.com',
    testingDomain: 'testing.xatakahome.com',
  },
  xatakamexico: {
    label: 'Xataka México',
    value: 'xatakamexico',
    instagramProfile: true,
    flipboard: true,
    domain: 'www.xataka.com.mx',
    testingDomain: 'testing.xataka.com.mx',
  },
  xatakacolombia: {
    label: 'Xataka Colombia',
    value: 'xatakacolombia',
    instagramProfile: false,
    flipboard: true,
    domain: 'www.xataka.com.co',
    testingDomain: 'testing.xataka.com.co',
  },
  xatakaargentina: {
    label: 'Xataka Argentina',
    value: 'xatakaargentina',
    instagramProfile: false,
    flipboard: false,
    domain: 'www.xataka.com.ar',
    testingDomain: 'testing.xataka.com.ar',
  },
  applesfera: {
    label: 'Applesfera',
    value: 'applesfera',
    instagramProfile: true,
    flipboard: true,
    domain: 'www.applesfera.com',
    testingDomain: 'testing.applesfera.com',
  },
  vidaextra: {
    label: 'Vida Extra',
    value: 'vidaextra',
    instagramProfile: true,
    flipboard: true,
    domain: 'www.vidaextra.com',
    testingDomain: 'testing.vidaextra.com',
  },
  genbeta: {
    label: 'Genbeta',
    value: 'genbeta',
    instagramProfile: false,
    flipboard: true,
    domain: 'www.genbeta.com',
    testingDomain: 'testing.genbeta.com',
  },
  compradiccion: {
    label: 'Compradiccion',
    value: 'compradiccion',
    instagramProfile: false,
    flipboard: true,
    domain: 'www.compradiccion.com',
    testingDomain: 'testing.compradiccion.com',
  },
  trendencias: {
    label: 'Trendencias',
    value: 'trendencias',
    instagramProfile: true,
    flipboard: true,
    domain: 'www.trendencias.com',
    testingDomain: 'testing.trendencias.com',
  },
  directoalpaladar: {
    label: 'Directo al Paladar',
    value: 'directoalpaladar',
    instagramProfile: true,
    flipboard: true,
    domain: 'www.directoalpaladar.com',
    testingDomain: 'testing.directoalpaladar.com',
  },
  vitonica: {
    label: 'Vitónica',
    value: 'vitonica',
    instagramProfile: true,
    flipboard: true,
    domain: 'www.vitonica.com',
    testingDomain: 'testing.vitonica.com',
  },
  trendenciasdecoracion: {
    label: 'Decoesfera',
    value: 'trendenciasdecoracion',
    instagramProfile: true,
    flipboard: true,
    domain: 'decoracion.trendencias.com',
    testingDomain: 'test.decoracion.trendencias.com',
  },
  directoalpaladarmexico: {
    label: 'Directo Al Paladar México',
    value: 'directoalpaladarmexico',
    instagramProfile: true,
    flipboard: true,
    domain: 'www.directoalpaladar.com.mx',
    testingDomain: 'testing.directoalpaladar.com.mx',
  },
  espinof: {
    label: 'Espinof',
    value: 'espinof',
    instagramProfile: true,
    flipboard: true,
    domain: 'www.espinof.com',
    testingDomain: 'testing.espinof.com',
  },
  mundoxiaomi: {
    label: 'Mundo Xiaomi',
    value: 'mundoxiaomi',
    instagramProfile: false,
    flipboard: true,
    domain: 'www.mundoxiaomi.com',
    testingDomain: 'testing.mundoxiaomi.com',
  },
  poprosa: {
    label: 'Poprosa',
    value: 'poprosa',
    instagramProfile: false,
    flipboard: true,
    domain: 'www.poprosa.com',
    testingDomain: 'testing.poprosa.com',
  },
  '3djuegospc': {
    label: '3DJuegos PC',
    value: '3djuegospc',
    instagramProfile: false,
    flipboard: true,
    domain: 'www.3djuegospc.com',
    testingDomain: 'testing.3djuegospc.com',
  },
  '3djuegos': {
    label: '3DJuegos',
    value: '3djuegos',
    instagramProfile: false,
    flipboard: true,
    domain: 'www.3djuegos.com',
    testingDomain: 'testing.3djuegos.com',
  },
  '3djuegoslat': {
    label: '3DJuegos Latam',
    value: '3djuegoslat',
    instagramProfile: false,
    flipboard: true,
    domain: 'www.3djuegos.lat',
    testingDomain: 'testing.3djuegos.lat',
  },
  '3djuegosguias': {
    label: '3DJuegos Guías',
    value: '3djuegosguias',
    instagramProfile: false,
    flipboard: true,
    domain: 'www.3djuegosguias.com',
    testingDomain: 'testing.3djuegosguias.com',
  },
  xatakaon: {
    label: 'Xataka On',
    value: 'xatakaon',
    instagramProfile: false,
    flipboard: false,
    domain: 'www.xatakaon.com',
    testingDomain: 'testing.xatakaon.com',
  },
  xatakaen: {
    label: 'Xataka En',
    value: 'xatakaen',
    instagramProfile: true,
    flipboard: false,
    domain: 'www.xatakaen.com',
    testingDomain: 'testing.xatakaen.com',
  },
  xatakabrasil: {
    label: 'Xataka Brasil',
    value: 'xatakabrasil',
    instagramProfile: true,
    flipboard: true,
    domain: 'www.xataka.com.br',
    testingDomain: 'testing.xataka.com.br',
  },
};

export const getSitesConfig = (site) => {
  return Object.values(sites);
};

export const sitesConfig = Object.values(sites);

export const domains = sitesConfig.map((site) => site.domain);
export const testingDomains = sitesConfig.map((site) => site.testingDomain);
export const devDomains = ['dev.xataka.com'];

export const getSiteDetails = (siteName) => sites[siteName];

export default sites;
