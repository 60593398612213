const configs = {
  production: {
    s3ApiKey:
      '3Nfs@snk#2K17$AJ&$AK-baa15-4FKH&R5%KCH78.01ab52-5a3e-4e3b-59115d32f',
    s3ApiDomain: 'https://api.weblogssl.com',
    host: 'http://admin.weblogssl.com',
    cloudName: 'weblogssl',
    uploadPreset: 'h2sbmprz',
    cssPath:
      'https://img.weblogssl.com/css/applesfera/admin-contenfoundry/main.css',
    rollbarToken: 'be647bf7ecd348548f2ca4ccd4f19956'
  },
  staging: {
    s3ApiKey:
      'Bn6*S-@mI7Bqd*1gxP4_23Kc^4G&i%TaJbtW@3w#4.z9Y-1*xPr=2a[dUMqY-ed@mdW',
    s3ApiDomain: 'https://testapi.weblogssl.com',
    host: 'http://test.admin.weblogssl.com',
    cloudName: 'weblogssl',
    uploadPreset: 'h2sbmprz',
    cssPath:
      'https://img.weblogssl.com/css/applesfera/admin-contenfoundry/main.css',
    rollbarToken: 'b19f089a5d104c76ba25b69d00fb4659'
  },
  development: {
    s3ApiKey:
      'Bn6*S-@mI7Bqd*1gxP4_23Kc^4G&i%TaJbtW@3w#4.z9Y-1*xPr=2a[dUMqY-ed@mdW',
    s3ApiDomain: 'https://testapi.weblogssl.com',
    host: 'http://test.admin.weblogssl.com',
    cloudName: 'weblogssl',
    uploadPreset: 'h2sbmprz',
    cssPath:
      'https://img.weblogssl.com/css/applesfera/admin-contenfoundry/main.css',
    rollbarToken: '1b4088a4c1d54aeeb5f0422b9e0d3dea'
  }
};

const configParams = configs[process.env.REACT_APP_ENV];

export default configParams;
